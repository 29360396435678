.iconPlus.theme--default {
	.primary {
		fill: var(--primary-green);
	}

	.accent {
		fill: var(--accent-live-green);
	}
}

.iconPlus.theme--liveGreen {
	.primary {
		fill: var(--primary-green);
	}

	.accent {
		fill: var(--text-light);
	}
}

.iconPlus.theme--yellow {
	.primary {
		fill: var(--primary-dark-green);
	}

	.accent {
		fill: var(--primary-green);
	}
}

.iconPlus.theme--green,
.iconPlus.theme--purple {
	.primary {
		fill: var(--text-light);
	}

	.accent {
		fill: var(--accent-live-green);
	}
}
